import React from 'react';
import styled from 'styled-components';
import { Header } from './components/Header';
import { MainGrid } from './components/MainGrid';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #333; /* Fondo oscuro estilo pixel art */
  color: #fff; /* Color del texto */
`;

function App() {
  return (
    <AppContainer>
      <Header />
      <MainGrid />
    </AppContainer>
  );
}

export default App;
