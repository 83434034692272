import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.main`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 2rem;
  margin-top: 20px;
  flex: 1;
`;

const Card = styled.div`
  background-color: #555;
  padding: 1rem;
  border: 2px solid #777;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const MainGrid = () => {
  return (
    <GridContainer>
      {/* Crear 6 tarjetas */}
      {Array.from({ length: 6 }, (_, index) => (
        <Card key={index}>Tarjeta {index + 1}</Card>
      ))}
    </GridContainer>
  );
};

export { MainGrid };
