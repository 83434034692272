import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #444;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Sidebar = styled.nav`
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')}; /* Muestra o esconde el menú */
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #222;
  transition: left 0.3s ease;
  padding: 1rem;
  z-index: 1000; /* Asegúrate de que el menú esté por encima del contenido */
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !event.target.closest('button')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <HeaderContainer>
        <MenuButton onClick={toggleMenu}>
          <FaBars />
        </MenuButton>
      </HeaderContainer>
      <Sidebar ref={sidebarRef} isOpen={isOpen}>
        {/* Aquí va el contenido del menú */}
        <ul>
          <li>Inicio</li>
          <li>Sobre Nosotros</li>
          <li>Contacto</li>
        </ul>
      </Sidebar>
    </>
  );
};

export { Header };
